
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


 
/* live new dot animation start */
/* .livenew_animation {
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  border-radius: 50%;
  top: 6.5px;
  left: 4.5px;
}
.livenew_animation.dot1 {
  width: 8px;
  height: 8px;
  border: 5px solid #62bd19;
  background-color: transparent;
  z-index: 1;
  opacity: 0.2;
  animation: dot1 2s infinite;
}
.livenew_animation.dot2 {
  width: 8px;
  height: 8px;
  background-color: #62bd19;
  z-index: 2;
  animation: dot2 2s infinite;
}
.livenew_animation.dot3 {
  width: 8px;
  height: 8px;
  background-color: #62bd19;
  z-index: 3;
  animation: dot3 1s alternate infinite;
}

@keyframes dot1 {
  0% {
    opacity: 0.2;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(5);
  }
}
@keyframes dot2 {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(2);
  }
}
@keyframes dot3 {
  0% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(-50%, -50%) scale(1.1);
  }
} */

.livenewdiv {
  width: 30px;
  display: inline-block;
}
 
.livenew_animation {
  position: absolute;
  width: 40px;
  left: 10px;
  top: 4px;
}
.livenew_animation .circle--outer {
  border: 1px solid #62bd19;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 auto 5px;
  position: relative;
  opacity: 0.8;
  -webkit-animation: circle 2s ease-in-out infinite;
  animation: circle 2s ease-in-out infinite;
}
.livenew_animation .circle--inner {
  background: #62bd19;
  left: 15px;
  top: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  opacity: 0.8;
}
.livenew_animation .circle--inner:after {
  content: "";
  display: block;
  border: 2px solid #62bd19;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  top: -4px;
  left: -4px;
  position: absolute;
  opacity: 0.8;
  -webkit-animation: circle 2s ease-in-out 0.2s infinite;
  animation: circle 2s ease-in-out 0.2s infinite;
}
  
@-webkit-keyframes circle {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
  }
}

@keyframes circle {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
  }
}

/* live new dot animation end */


.loader_box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 9;
  background: rgba(255,255,255,0.8);
  left: 0;
  top: 0;
}

.loader_box .circle-border {
  width: 120px;
  height: 120px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ff6600;
  background: linear-gradient(0deg, rgba(255,102,0,0.1) 33%, rgba(255,102,0,1) 100%);
  animation: spin .8s linear 0s infinite;
}

.loader_box .circle-core {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 50%;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(359deg);
  }
}

/* start sitemap css */
.sitemap-links ul {list-style: none;position: relative;}
.sitemap-links ul li{position: relative;padding: 5px 15px;}
.sitemap-links ul li a{color:#000;text-decoration: none;}
.sitemap-links ul li a:hover{color:#f60;}
.sitemap-links ul li:after,
.sitemap-links ul li:before {content: "";position: absolute;top: 0;left: 0;border-width: 1px;z-index: 0;}
.sitemap-links ul li:before {left: -1.1em;height: 150%;border-top-style: none;border-left-style: solid;}
.sitemap-links ul li:after {top: 1em;left: -1.1em;width: 1.2em;border-top-style: solid;}
.sitemap-links ul li:last-child:before {border-left: none;}
/* end sitemap css */