@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Russo+One&family=Teko:wght@300..700&display=swap);

* {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
}

ul,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

/* .container {
    width: 1140px !important;
} */

p {
    margin: 0;
    font-size: 17px;
}
body {
    font-family: "Raleway", sans-serif;
}

.padd {
    padding: 34px 0px;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

header{
    position: sticky;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 9999;
}
header a {
    color: #fff !important;
}

.top-header {
    padding: 8px 0px;
    background: #f60;
}

.top-header .top-header-list li a.search-icon img {
    width: 18px;
    position: absolute;
    right: 14px;
    top: 2px;
}

.top-header .top-header-list li a.search-icon {
    background: none;
    position: relative;
}


.top-header .top-header-list li {
    display: inline-block;
}

.top-header .top-header-list li a {
    padding: 3px;
    height: 32px;
    border-radius: 60%;
    width: 32px;
    text-align: center;
    margin: 0px 3px;
    transition: 0.5s;
    background-color: #fff;
}

.top-bar {
    line-height: 1;
    backdrop-filter: blur(5px);
    background-color: #000066;
    z-index: 1000;
    width: 100%;
    border-bottom: 1px solid #ddd;
}

#logo img {
    width: 126px;
    background-color: #fafafa;
}

.main-menu li:hover a {
    color: #f60;
    transition: 0.3s;
}

.mobile-menu i {
    font-size: 24px;
}

.main-menu {
    margin-left: 50px;
}

.active {
    color: #f60 !important;
}

header .top-header input.search-btn-style {
    background: #fff;
    border: none;
    border-radius: 20px;
    padding: 4px 12px;
    color: #000066;
    height: 35px;
}

header .top-header input.search-btn-style:focus {
    outline: none;
}

.main-menu li a {
    margin: 0px 2px;
    font-weight: 600;
    display: block;
    white-space: nowrap;
    padding: 0 20px !important;
    text-decoration: none;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
}
/*------------ Live Css Add -------------------*/
#live-tv-btn {
    opacity: 999999;
    z-index: 9999;
    text-transform: uppercase;
    background-image: linear-gradient(-225deg,
            #231557 0%,
            #44107a 29%,
            #ff1361 67%,
            #fff800 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 18px !important;
    font-weight: 600;
    border-radius: 4px;
    border: 1px solid #fff;
    transition: 0.3s;
    padding: 4px 14px !important;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}
.weather span.weather-value {
    color: #fff;
    font-weight: 500;
    font-size: 13px;
    position: absolute;
    bottom: 0;
    left: -26px;
}
.weather{
    position: relative;
    margin-right: 26px;
    cursor: pointer;
}
.weather-icon {
    width: 40px;
    
}
button.languagebtn-style {
    background: #ff6600;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 16px;
    border: 1px solid #fff;
    transition: 0.3s;
}
button.languagebtn-style:hover {
    background: transparent;
    border: 1px solid #ddd;
}
.trending-section .trending-btn {
    background: #ff6600;
    text-align: center;
    font-size: 21px;
    color: white;
}

.trending-section p {
    font-size: 17px;
    padding: 4px 0px;
}

.trending-section p span {
    background: #0000662e;
}


.breaking-news-section .leftarea-inner {
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid #fff;
    align-items: center;
    padding: 18px 16px;
}


.breaking-news-item {
    display: inline-block;
    margin-right: 10px;
}

.breaking-news-heading {
    z-index: 1;
    /* Ensure the heading stays above the scrolling text */
}





section.masthead {
    position: relative;
    background-color: #343a40;

    background-image: url("../assets/images/animation.gif");
    /* background-image: url("https://akm-img-a-in.tosshub.com/aajtak/images/bigstory/bigstory-template-final-2_5.jpg"); */


    background-size: cover;
    padding-top: 8rem;
    padding-bottom: 8rem;
    z-index: 999;
    background-repeat: no-repeat;
    background-position: center;
}

section.masthead:after {
    background: rgb(0 0 102 / 22%);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
}

section.masthead h1 {
    font-size: 3rem;
    font-weight: 900;
}

section.masthead .Banner-sec h4 {
    font-size: 22px;
    font-weight: 300;
    line-height: 34px;
}

section.masthead .cstm-btn a span {
    padding-left: 8px;
}

section.masthead .cstm-btn a {
    background: white;
    padding: 3px 28px;
    border-radius: 12px;
    font-size: 17px;
    font-weight: 500;
    color: black;
    text-decoration: none;
    display: inline-block;
    transition: 0.4s;
}
.footer-logo{
    width: 128px;
}
section.masthead .cstm-btn img {
    width: 40px;
}

section.masthead .cstm-btn a:hover {
    background: #ff6600;
    color: white;
}

/* Ensure the iframe scales with the container width */
.responsive-iframe-container {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 Aspect Ratio (9 / 16 = 0.5625 or 56.25%) */
    padding-top: 25px;
    /* Give some top padding for better viewing experience on mobile devices */
    height: 0;
    overflow: hidden;
}

.responsive-iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.box p.sub-title-text {
    font-size: 16px !important;
    font-weight: 400 !important;
}

.slider-box {
    float: left;
    width: 310px;
    margin-right: 18px;
    cursor: pointer;
}

.slider-box p {
    font-size: 16px;
    padding-top: 12px;
    font-weight: 500;
}

.slider-box.slidermr-0 {
    margin-right: 0;
}

.slider-box:hover p {
    color: #f60;
    transition: 0.3s;
}

.features ul li a {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 4px;
    color: #000;
    text-decoration: none;
    display: inline-block;
    /* display: flex; */

    width: 100%;
    align-items: center;
}

.features ul li {
    display: inline-block;
    width: 100%;
}

.features ul li a img {


    margin-top: 4px;

    margin-bottom: 25px;

}

.features .box p {
    font-size: 18px;
    font-weight: 600;
    padding-top: 6px;
}

.features .box img {
    width: 100%;
}

.features .box a {
    color: black;
    text-decoration: none;
}

.features span.latest-news {
    color: darkgrey;
    display: inline-block;
}

.features .a-border.div1 {
    margin-left: 0;
}

/* .features ul li:first-child {
	border-bottom: 1px solid rgb(255 102 0);
	padding: 3px 0px;
} */
.features ul li.frist_child {
    border-bottom: 1px solid rgb(255 102 0);
    padding: 3px 0px;
    margin-bottom: 16px;
}

.features ul.latestNews li a {
    line-height: normal;
    font-size: 13px;
    text-transform: capitalize;
    font-weight: 400;
    display: flex;
    transition: 0.4s;
}

.features ul.latestNews li a p {
    margin-left: 14px;
}

.features ul.latestNews a:first-child {
    border: none !important;
}

.add-section li a:first-child {
    border: none !important;
}

.features ul.latestNews li a:hover p {
    color: rgb(255 102 0);
    text-decoration: underline;
}

.features .box {
    margin-bottom: 20px;
}

.features ul.vertical-menu li {
    padding: 12px 15px;
    display: inline-block;
    margin: 4px;
    background: #000;
    width: auto;
    transition: 0.4s;
}

.features ul.vertical-menu {
    padding: 0;
    text-align: left;
}

.features ul.vertical-menu li:hover {
    background: #000066;
}

.features ul.vertical-menu li:hover a {
    color: #fff;
}

.features ul.vertical-menu li a {
    border: none !important;
    font-size: 14px;
    text-transform: capitalize;
    color: #fff;
}

.latest-video .read-more a {
    border-radius: 0;
    color: #ffffff;
    padding: 10px 35px;
    font-size: 16px;
    text-transform: uppercase;
    transition: all 0.2s ease 0s;
    background: rgb(255 102 0);
    text-decoration: none;
}

.latest-video .read-more a:hover {
    background: #006;
    transition: 0.6s;
    box-shadow: 0 0 5px #ff6600f0;
}

.latest-video .card .card-body {
    padding: 0px;
}

.latest-video .card {
    border: 0;
    background: none;
}

.latest-video .rotate-img {
    overflow: hidden;
}

.latest-video .rotate-img:hover img {
    -webkit-transform: rotate(15deg) scale(1.4);
    transform: rotate(15deg) scale(1.4);
}

.latest-video .rotate-img img {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    -webkit-transition: 0.6s ease-in-out;
    transition: 0.6s ease-in-out;
}

section.latest-video.features .badge-positioned {
    position: absolute;
    left: 1rem;
    bottom: 1rem;
}

section.latest-video.features .badge {
    padding: 12px 10px;
    font-size: 14px;
    border-radius: 0;
    line-height: 0;
    font-weight: 400;
}

section.latest-video.features .badge-danger {
    color: #fff;
    background-color: rgb(255 102 0);
}

section.latest-video.features .video-icon {
    width: 35px;
    height: 35px;
    border-radius: 100px;
    color: #343a40;
    font-size: 1rem;
    background: #ffffff;
    text-align: center;
    padding-top: 10px;
    line-height: 0;
}

section.latest-video.features .video-icon a i {
    color: #006;
}

section .grid-margin {
    margin-bottom: 2rem;
}

section.latest-video.features .w-90 {
    width: 90%;
}

.customer-logos img {
    width: 160px;
    margin: 8px;
    box-shadow: 1px 1px 8px #ddd;
    transition: 0.6s;
}

.customer-logos img:hover {
    box-shadow: 1px 1px 4px 1px #33333385;
}

.slider-sections .slick-prev,
.slick-next {
    position: absolute;
    top: 135%;
    font-size: 1.8rem;
}

.slider-sections .slick-prev {
    left: 0;
}

.slider-sections .slick-next {
    right: 0;
}

.slider-sections .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slider-sections .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slider-sections .slick-list:focus {
    outline: none;
}

.slider-sections .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slider-sections .slick-slider .slick-track,
.slider-sections .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slider-sections .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
}

.slider-sections .slick-track:before,
.slider-sections .slick-track:after {
    display: table;
    content: '';
}

.slider-sections .slick-track:after {
    clear: both;
}

.slider-sections .slick-loading .slick-track {
    visibility: hidden;
}

.slider-sections .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slider-sections .slick-slide img {
    display: block;
}

.slider-sections .slick-slide.slick-loading img {
    display: none;
}

.slider-sections .slick-slide.dragging img {
    pointer-events: none;
}

.slider-sections .slick-initialized .slick-slide {
    display: block;
}

.slider-sections .slick-loading .slick-slide {
    visibility: hidden;
}

.slider-sections .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slider-sections .slick-arrow.slick-hidden {
    display: none;
}

.slider-sections .slide {
    transition: filter .4s;
    margin: 0px 40px;
}

section.slider-sections .slide-in-right {
    border: 1px solid #ddd;
}

.slider-sections .fa {
    color: #000066;
    font-size: 40px !important;
    font-weight: 600;
    cursor: pointer;
}

.slider-sections .slick-prev {
    left: 0;
    z-index: 999;
}

.slider-sections .slick-prev,
.slick-next {
    position: absolute;
    top: 35%;
    font-size: 1.8rem;
}

section.slider-sections h3 {
    font-size: 28px;
    text-transform: uppercase;
    padding-bottom: 20px;
    font-weight: bold;
    color: #006;
}

section.slider-sections .section-title {
    padding-bottom: 40px;
}

section.slider-sections {
    background: #0000660a;
    box-shadow: 0px 0px 2px 0px #006;

}



footer .footer-top {
    background: url('../assets//images//main-footer-top-bg.jpg');
    color: white;
    text-align: left;
}

footer .footer-top .footer-border-bottom {
    border-bottom: 1px solid rgba(99, 99, 99, 0.2);
}

footer .footer-top .count {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: #fff;
    font-size: 10px;
    text-align: center;
    padding-top: 1px;
    color: #000066;
}

footer .footer-top h5 {
    font-size: 16px;
    font-weight: 400;
}

.footer-top h3 {
    font-size: 18px;
    border-bottom: 1px solid #626161;
    padding-bottom: 10px;
}

footer .footer-top .social-media li a {
    padding: 6px 7px;
    transition: 0.6s;
    height: 38px;
    width: 38px;
}

footer .social-media li {
    padding: 0 4px;
}

footer .footer-top .social-media li a i {
    color: #032a63;
    font-size: 20px;
}

footer .footer-top .social-media {
    padding-left: 0;
}

footer .social-media {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 22px;
}

ul.footer-menu li a:hover {
    color: #f60;
}
footer .footer-top .social-media li a:hover i {
    color: #fff;
}

.main-footer {
    background: url('../assets//images//main-footer-top-bg.jpg');
    color: #fff;
    padding: 10px 0px;
    box-shadow: -1px 0px 1px #fff;
    /* text-transform: uppercase; */
}

/*----- News CSS Start  -----*/
.news-section .story-cart h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: var(--catbrandcolor, #c00);
    text-transform: uppercase;
    padding-bottom: 10px;
}

.news-section .story-cart h2 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 500;
    margin: 0 10px 10px 0;
}

.news-section .story-cart {
    border-radius: 4px;
    padding: 15px 20px 20px;
    border: 1px solid #ddd;
}

.blog .section-tittle h3 {
    font-size: 24px;
    font-weight: 600;
    padding: 18px 0px;
}

.blog .about-pera1 {
    padding-bottom: 30px;
}

.Our-Show h5 {
    font-size: 17px;
}

.Our-Show h4 {
    font-size: 22px;
    font-weight: 500;
}

.cursor-pointer-div:hover h4 {
    color: #f60;
}

.cursor-pointer-div:hover h5 {
    color: #f60;
}

.on-going-news {
    padding: 10px;
}

.on-going-news h2 {
    font-size: 22px;
}

.truncate-text {
    display: -webkit-box;
    -webkit-line-clamp: 8;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* Additional styles to ensure it's responsive if necessary */
    max-width: 100%;
    /* or any other value */
}

.truncate-text-list {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* Additional styles to ensure it's responsive if necessary */
    max-width: 100%;
}

.contact-us button.button-contactForm:hover {
    background: #f60 !important;
    color: #fff !important;
    box-shadow: 0 0 4px #006;
}

.contact-us button.button-contactForm {
    background: #006;
    color: #ffffff;
    display: inline-block;
    padding: 10px 36px;
    font-size: 19px;
    font-weight: 400;
    border: none;
    text-align: center;
    text-transform: capitalize;
    transition: 0.3s;
}

.contact-us .form-control:focus {
    border: 1px solid #006;
    box-shadow: none;
}

ul.footer-menu li a {
    text-decoration: none;
    color: #fff;
    transition: 0.5s;
}

ul.footer-menu li {
    display: inline-block;
    margin-left: 36px;
}
.blog-card {
    border: 1px solid rgba(0, 0, 0, .125);
    padding: 14px 14px 40px 14px;
    background: #fff;
}

.blog-card .corner {
    position: absolute;
    left: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 10px 10px;
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #009999 rgba(0, 0, 0, 0);
}

.blog-card .corner:before {
    content: "";
    position: absolute;
    left: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 10px 11px;
    border-color: rgb(255 102 0) rgb(255 102 0) rgb(255 102 0) rgb(255 247 242)
}

.blog-card .post-date-ribbon {
    margin-top: -30px;
}

.blog-card .post-date-ribbon {
    line-height: 25px;
    color: #fff;
    font-size: 12px;
    margin-top: -25px;
    position: relative;
    padding: 0 7px;
    float: left;
    background: #f60;
}

.blog-card .about-author img {
    border-radius: 50%;
    width: 90px;
    object-fit: cover;
    height: 90px;
    border: 1px solid #ddd;
}

.blog-card .about-author .media-body p {
    font-size: 15px;
    font-weight: 400;
}

.blog-card .social-medialinks ul {
    padding: 0;
}

.blog-card .social-medialinks ul li {
    background: #006;
    padding: 6px 6px 0px;
    display: inline-block;
    width: 36px;
    margin-right: 3px;
    border-radius: 50%;
    height: 36px;
}

.blog-card .social-medialinks ul li a {
    border: none !important;
    color: #fff;
}

.blog-card .social-medialinks ul li:hover {
    background: #f60;
    color: #006;
    transition: 0.3s;
}

.youtube-video-slider .next-pre-btn {
    position: absolute;
    top: 44%;
    width: 100%;
}

.youtube-video-slider .next-pre-btn img.videoimg001 {
    left: 50px;
    position: absolute;
}

.youtube-video-slider .next-pre-btn img.videoimg002 {
    right: 50px;
    position: absolute;
}

.youtube-video-slider .next-pre-btn img {
    width: 42px;
    height: 42px;
    background: #ff6600;
    padding: 12px 16px;
    cursor: pointer;
    border-radius: 50px;
}

.youtube-area .video-info .video-caption .bottom-caption h2 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 27px;
}

ul.youtube-videos li {
    display: inline-block;
    cursor: pointer;
    text-align: left;
}

ul.youtube-videos li p {
    font-weight: 600;
    margin: 0px 30px;
}

.bottom-caption h2 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 26px;
}

.bottom-caption,
ul.youtube-videos {
    margin-top: 40px;
}



.top-news-sec ul {
    background: #006;
    padding: 10px;
    color: #fff;
}

.top-news-sec ul.top-news-list li {
    display: flex;
    padding: 10px;
    border-bottom: 1px dotted #fff;
}

.top-news-sec ul.top-news-list li p {
    font-size: 14px;
    padding-right: 22px;
}

.top-news-sec ul.top-news-list li:last-child {
    border: none;
}

ul.social_media_right li {
    transition: 0.6s;
}

footer .footer-top .social-media li a:hover {
    background: #006 !important;
    color: #fff !important;
    box-shadow: 0 0 16px #ff6600f0;
}

.PrevNext a:hover {
    background: #ff4c00;
    color: #ffffff !important;
    border: none;
    transition: 0.3s;
}

.PrevNext a,
.PrevNext a:visited {
    color: #fff;
    background: #006;
    padding: 6px 6px;
    margin: 0px 8px;
    text-decoration: none;
    font-size: 16px;
    border-radius: 3px;
    display: inline-block;
    width: 83px;
    text-align: center;
}

.newsbox-style h3 {
    padding-bottom: 12px;
    font-size: 22px;
}

.newsbox-style {
    border: 1px solid #00006640;
    display: inline-block;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0px 0px 2px 0px #00006626;
}

.newsbox-style:hover {
    border: 1px solid #006;
    transition: 0.4s;
    box-shadow: 2px 0px 5px 0px #00006626;
    background: #bcb0cd12;
}

img.img-fluid.img-shadowdiv1 {
    box-shadow: -3px 1px 5px 0px #00006626;
}

.privacy-back {
    border: 1px solid #dddddd4f;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 2px 2px 0px -2px #ddd;
    background: #f5f5f970;
}

.privacy-section h4 {
    color: #006;
    font-size: 20px;
    font-weight: 500;
    margin: 12px 0px;
}

.privacy-section ul li {
    list-style: circle;
    margin-left: 20px;
    color: #000000;
}

.privacy-section ul {
    line-height: 2;
}

.privacy-section h3 {
    color: #006;
    font-weight: 600;
    font-size: 28px;
}

/*-----All Category CSS---*/
.live-news.all-category h4 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 18px;
    color: #006;
}

ul.player-slider li {
    background-color: #e1eaff38;
    border: 1px solid #00006630;
    margin-right: 10px;
    min-height: 250px;
    display: inline-block;
    text-align: left;
    width: 30.2%;
}

ul.player-slider li img {
    width: 100%;
    min-height: 130px;
}

.top-news-section-div ul li a {
    text-decoration: none;
    padding-bottom: 10px;
    margin-bottom: 10px;
    display: inline-block;
    border-bottom: 1px solid #dfdfdf;
    color: #333;
    font-size: 16px;
}

ul.player-slider p {
    padding: 16px;
    font-weight: 500;
}

.Intertenment-img h5 {
    position: absolute;
    bottom: 10px;
    color: #fff;
    font-size: 25px;
    padding: 12px 16px;
}

.Intertenment-img:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 1%, rgba(180, 180, 180, 0) 30%, rgba(0, 0, 0, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 1%, rgba(180, 180, 180, 0) 30%, rgba(0, 0, 0, 1) 100%);
}

.news-post h5 {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
}

.backgroundclr {
    background: #f4f4f4;
}

.news-post {
    border-bottom: 1px solid #99999969;
}

/*=============== Sports CSS =============*/
.live-news.all-category.sports h6 {
    line-height: 34px;
    font-size: 19px;
}

.live-news.all-category .sports-heading-bg {
    background: #e1eaff38;
    border: 1px solid #00006630;
    padding: 15px;
    height: 100%;
}


/* categories section css start */
.categories-section .cat_menu_list {
    display: flex;
    position: relative;

    margin: 0 auto;
}

.sub_menu_items {
    display: flex;
    gap: 20px;
}

.categories-section .menu_item {
    padding: 20px 0;
    color: #333;
    text-decoration: none;
    display: inline-block;
    margin: 0 10px;
    text-wrap: nowrap;
}

.categories-section .menu_item:hover {
    color: #ff6600;
    transform: 0.5s;
}

.categories-section .sub_menu {
    position: absolute;
    left: 0;
    background: #fff;
    z-index: 99999999999;
    width: 100%;
    top: 100%;
    padding: 20px 40px;
    border-top: 1px solid #908faa;
    display: none;
    box-shadow: 0px 15px 10px -15px #111; 
}

.cat_menu_list>li:hover>a {
    color: #ff6600;
}

.cat_menu_list>li:hover .sub_menu {
    display: block;
}

.sub_menu_item {
    color: #333;
    text-decoration: none;
    line-height: 20px;
}

.sub_menu_item p {
    font-size: 16px;
}

.menu_item_img {
    margin-bottom: 10px;
    height: 170px;
}

.menu_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    object-position: center;
}
.sub_menu_items li {
    width: 25%;
}
/* ----------Category page css------------ */
.Categorie-section1 a.news-categorie-subhead {
    background: #f4f4f4;
    display: inline-block;
    padding: 10px;
    font-size: 14px;
    margin-bottom: 3px;
}

.Categorie-section1 a.news-categorie-subhead span,
.Categoriesub-heading {
    background: #006;
    padding: 3px 12px;
    color: #fff;
    border-radius: 2px;
    display: inline-block;
    margin-bottom: 6px;
}

.Categorie-section1 a {
    color: #000;
    text-decoration: none;
}

.Categorie-section1 a.news-categorie-subhead:hover span {
    background: #f60;
    transition: 0.4s;
}

a.news-categorie-subhead.wwe-spoart-sec span.news-categorie-subhead {
    background: #f60;
    margin-bottom: 10px;
}

.live-news.all-category.web-stories img {
    width: 100%;
    height: 201px;
    object-fit: contain;
}
.search-icon .searchbtn-icon {
    position: absolute;
    right: 0;
    background: #fff;
}
.tab-section button.active {
    background: #f60;
    color: #fff !important;
}
.tab-section button {
    padding: 14px 20px;
    background: #333;
    border: none;
    margin: 0px 4px;
    color: #fff;
    font-size: 20px;
    transition: 0.4s;
}
@media (min-width:768px) and (max-width:1024px){
    header button.navbar-toggler {
        background: #f60;
        border-radius: 50%;
        margin-right: 10px;
        height: 36px;
        width: 36px;
        padding: 0;
    }

    header button.navbar-toggler:focus {
        box-shadow: none;
    }

    .navbar-toggler-icon {
        font-size: 14px;
    }

    .main-menu li a {
        text-align: left;
        padding: 11px 23px !important;
        font-size: 18px;
    }

    .main-menu li a:after {
        background: none;
    }

    ul.navbar-nav.main-menu {
        padding-bottom: 10px;
    }

    .main-menu {
        margin-left: 0;
    }

    ul.navbar-nav.main-menu li {

        border-bottom: 1px solid #ffffff47;
    }

    div#navbarSupportedContent {
        padding-bottom: 24px;
        background: #006;
    }
    nav.navbar.navbar-expand-lg {
        background: #fff;
    }
}
@media screen and (max-width: 767px) {
    .social-icons-container{
        margin-bottom: 8px;
    }
    form.job-apply-form{
        width: 100% !important;
    }
    form.job-apply-form .inputwith{
        width: 100% !important;
        margin: 6px 0px !important;
    }
    .top-header p a {
        display: none;
    }
    .live-btn-responsive.d-flex {
        padding: 0px 20px;
    }
    header .top-header input.search-btn-style {
        width: 162px;
    }

    #logo img {
        background: none;
    }

    nav.navbar.navbar-expand-lg {
        background: #fff;
    }

    .Categorie-section1 a.news-categorie-subhead {
        display: inline-block !important;
        margin-bottom: 12px;
    }

    .top-header {
        text-align: center;
    }

    .customer-logos img {
        width: 115px;
    }

    .contact-info-item {
        height: 196px !important;
    }

    .about_bg_profile_style {
        display: none;
    }

    .bg-style-profilediv {
        padding: 10px;
    }

    .categories_overflow_menu {
        overflow-x: auto;
    }

    .top-news-section-div {
        margin-bottom: 20px;
    }
}

/* categories section css end */

/* social media right css start */
.social_media_right {
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 9999999;
    transform: translateY(-50%);
}

.social_media_right li a {
    display: flex;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    position: relative;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #ff6600;
    height: 40px;
    width: 40px;
    margin: 2px 0;
    transition: 0.4s;
}

.social_media_right li a:hover {
    background: #000066;
}

.social_media_right .soc_title {
    overflow: hidden;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    background: #ff6600;
    line-height: 34px;
    right: 41px;
    padding: 0 12px;
    transition: 0.4s;
}

.social_media_right a:hover .soc_title {
    visibility: visible;
    opacity: 1;
    width: auto;
}

/* social media right css start */

.politics_img {
    height: 70px;
}

.politics_img img {
    height: 100%;
    width: 100%;
    /* object-fit: cover; */
    object-position: top center;
}








@media (max-width: 768px) {
    .about-us .carousel-inner .carousel-item>div {
        display: none;
    }

    .politics_img {
        height: auto;
    }

    .about-us .carousel-inner .carousel-item>div:first-child {
        display: block;
    }

    button.languagebtn-style,
    #live-tv-btn {
        padding: 4px 10px;
        font-size: 15px;
        text-transform: capitalize;
        
    }

    section.masthead h1 {
        font-size: 2rem;
        margin-bottom: 6px !important;
    }

    section.masthead .Banner-sec h4 {
        font-size: 20px;
        line-height: normal;
    }

    /* section.masthead:after {
        background: rgb(255 76 0 / 65%)
    } */


    .trending-section .trending-btn {
        font-size: 16px;
    }

    #logo img {
        width: 128px;
    }

}

.main-menu li a:hover {
    color: #f60 !important;
}

.about-us .carousel-inner .carousel-item.active,
.about-us .carousel-inner .carousel-item-start,
.about-us .carousel-inner .carousel-item-next,
.about-us .carousel-inner .carousel-item-prev {
    display: flex;
}

.about-us .card.card-body {
    border: 1px solid #fff !important;
    border-radius: 0;
    padding: 0;
    margin: 0px 2px;
}

section.about-us h3 {
    font-size: 28px;
    text-transform: uppercase;
    padding-bottom: 30px;
    font-weight: bold;
    color: #006;
}

section.about-bg {
    background-color: #ff660094;
}

section.about-us .carousel-control-next,
.carousel-control-prev {
    top: 20%;
}

/* display 4 */
@media (min-width: 768px) {

    .about-us .carousel-inner .carousel-item-right.active,
    .about-us .carousel-inner .carousel-item-next,
    .carousel-item-next:not(.carousel-item-start) {
        transform: translateX(25%) !important;
    }

    .about-us .carousel-inner .carousel-item-left.active,
    .about-us .carousel-item-prev:not(.carousel-item-end),
    .active.carousel-item-start,
    .about-us .carousel-item-prev:not(.carousel-item-end) {
        transform: translateX(-25%) !important;
    }

    .about-us .carousel-item-next.carousel-item-start,
    .active.carousel-item-end {
        transform: translateX(0) !important;
    }

    .about-us .carousel-inner .carousel-item-prev,
    .about-us .carousel-item-prev:not(.carousel-item-end) {
        transform: translateX(-25%) !important;
    }
}
.carrierpage button.accordion-button {
    background: #fff !important;
    color: #006 !important;
    font-size: 18px !important;
    font-weight: 600;
}
.carrierpage .accordion-item {
    border-radius: 0 !important;
    border: 1px solid #ddd !important;
    margin: 4px 0px;
}
.carrierpage .accordion-collapse{
    position: relative;
}
.carrierpage button.accordion-button:focus{
    box-shadow: none;
}
.carrierpage .job-apply{
    position: absolute;
    right: 20px;
    top: 18px;
}
.carrer-section .career-message {
    padding: 8px 16px;
    background: #f60;
    border: none;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    border-radius: 4px;
    text-decoration: none;
    font-size: 17px;
    transition: 0.4s;
    font-weight: 500;
}
.carrierpage .job-details strong {
    color: #ff6600;
    padding-right: 3px;
    font-size: 17px;
}
.carrierpage .job-details p {
    padding-bottom: 12px;
    font-size: 16px;
}
.contact-us form {
    padding: 31px !important;
    background: #c7bfbf3d;
}
.contact-us form textarea {
    border: 1px solid #ddd !important;
}
.contact-info-item {
    background: #006;
    box-shadow: 4px 4px 20px #000000ba;
    display: inline-block;
    align-items: center;
    transition: 0.5s;
    height: 260px;
}

.contact-info-item:hover .fa {
    color: #f60 !important;
    transition: 0.3s;
}

.contact-info-item::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.1);
    clip-path: polygon(66% 0, 100% 0, 100% 100%, 33% 100%);
}

.contact-info-item:hover {
    background: #f60;
    cursor: pointer;
    border: none !important;
}

.scroll-to-top-arrow {
    position: fixed;
    padding-top: 4px;
    bottom: 20px;
    right: 20px;
    background-color: #ff6600;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 38px;
    text-align: center;
    height: 38px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 9999
}
button.submitbtn-bgchanged {
    padding: 7px 26px;
    background: #f60;
    color: #fff;
    border: none;
    border-radius: 4px;
}
.latest-news-shadowdiv {
    box-shadow: 2px 2px 0px -2px #ddd;
    background: #f5f5f970;
}

@media screen and (min-width: 768px) {

    section.masthead {
        padding-top: 12rem;
        padding-bottom: 12rem;
    }
}

.scroll-to-top-arrow.visible {
    opacity: 1;
}

@media screen and (min-width: 768px) {
    #live-tv-btn {
        margin-right: 20px;
    }
    section.masthead {
        padding-top: 12rem;
        padding-bottom: 12rem;
    }

}

@media (max-width: 767px) {
    .weather span.weather-value{
        color: #000;
    }
    .marketbox{
        margin: 10px 0px;
    }
    section.padd.advertise .card {
        margin: 26px 0px;
    }
    .carrer-section a{
        margin-bottom: 20px;
    }
    .carrierpage .job-apply{
        position: sticky;
    }
    .weather {
        position: absolute;
        top: 8px;
        right: 12%;
    }
    .Intertenment-img img {
        width: 100%;
        height: 290px;
        object-fit: cover;
    }

    header button.navbar-toggler {
        background: #f60;
        border-radius: 50%;
        margin-right: 10px;
        height: 36px;
        width: 36px;
        padding: 0;
    }

    header button.navbar-toggler:focus {
        box-shadow: none;
    }

    .navbar-toggler-icon {
        font-size: 14px;
    }

    .main-menu li a {
        text-align: left;
        padding: 11px 23px !important;
        font-size: 18px;
    }

    .main-menu li a:after {
        background: none;
    }

    ul.navbar-nav.main-menu {
        padding-bottom: 10px;
    }

    .main-menu {
        margin-left: 0;
    }

    ul.navbar-nav.main-menu li {

        border-bottom: 1px solid #ffffff47;
    }

    div#navbarSupportedContent {
        padding-bottom: 24px;
        background: #006;
    }

    .section-to-hide {
        display: none;
    }

    ul.footer-menu li {
        margin: 0 auto;
        display: block;
        text-align: center;
        padding: 2px 0px;
    }

    .main-footer p {
        text-align: center !important;
    }

    ul.footer-menu {
        padding: 0;
    }

    .padd {
        padding: 16px 0px;
    }

    section.slider-sections h3 {
        font-size: 22px;
    }

    .customer-logos {
        padding-bottom: 10px;
    }

    section.slider-sections .section-title {
        padding-bottom: 16px;
    }

    .features ul li.frist_child strong {
        text-transform: capitalize !important;
    }

    .features ul li.frist_child {
        margin-bottom: 8px;
    }

    .footer-top h3 {
        font-size: 14px;
        margin-top: 14px;
    }

    footer .footer-top img {
        width: 100px;
        padding-top: 8px !important;
    }

    footer .footer-top p,
    h5 {
        font-size: 14px !important;
    }

    .newsbox-style h3 {
        font-size: 19px;
    }

    .on-going-news h2 {
        font-size: 20px;
    }

    .latest-news-shadowdiv {
        padding: 0;
        border: none;
    }
}

.live-news.all-category .news-post:hover {
    color: #f60;
}

.ytp-large-play-button {
    width: 46px !important;
}

.bg-style-profilediv {
    border-radius: 0 !important;
    background: #373737 !important;
    color: #fff;
}

.about_bg_profile_style {
    background: #ff6600;
    border-top-right-radius: 35%;
    box-shadow: 2px -1px 0px 1px #ffffff;
}

.footer-border-bottom img {
    width: 80px !important;
    height: 48px;
    object-fit: cover;
}

section .bg-style-profilediv a:hover {
    background: #006 !important;
    color: #fff !important;
    box-shadow: 0 0 16px #ff6600f0;
    transition: 0.6s;
}

section .bg-style-profilediv a {
    display: inline-block;
    height: 36px;
    width: 36px;
    text-align: center;
    transition: 0.5s;
    border-radius: 50px;
    font-size: 23px;
    background-color: rgb(255, 102, 0);
    color: rgb(255, 255, 255);
    margin: 0px 4px;
}

.about_bg_profile_style img {
    height: 252px;
    padding-top: 20px;
}

.about_bg_profile_style img {
    height: 252px;
    padding-top: 20px;
    width: 222px;
    object-fit: contain;
}

h2.latestbracking-news {
    font-size: 26px;
    font-weight: 600;
    margin-left: -10px;
    color: #f60;
    padding-bottom: 6px;
}
h1.latestbracking-news {
    font-size: 26px;
    font-weight: 600;
    margin-left: -10px;
    color: #f60;
    padding-bottom: 6px;
}

.scroll-to-top-arrow.visible:hover {
    background: #006 !important;
    color: #fff !important;
    box-shadow: 0 0 16px #ff6600f0;
    transition: 0.6s;
}

ul.searchlist li:hover {
    color: #006;
}

ul.searchlist li {
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #fff;
    padding: 6px 12px;
    color: #fff;
    transition: 0.4s;
}

ul.searchlist li a {
    background: none !important;
    text-decoration: none;
    font-size: 14px;
    padding: 4px 0px;
}

ul.searchlist li:hover {
    /* cursor: pointer; */
}

ul.searchlist {
    position: absolute;
    top: 34px;
    background: #f60;
    z-index: 9999;
    width: 100%;
}

.searchbtn-icon {
    position: absolute;
    right: 5px;
    top: 4px;
    color: #f60;
    border-radius: 50%;
    width: 30px !important;
    height: 30px !important;
    padding: 2px;
    cursor: pointer;
}

.thanks-message-sett {
    height: 160px;
    position: absolute;
    top: 120px;
    background: #ddd;
    padding: 30px !important;
}

ul.footer-border-bottom li a {
    text-decoration: none;
    transition: 0.5s;
    color: #fff;
    padding-left: 8px;
}
ul.footer-border-bottom li a:hover{
    color: #f60 !important;
}

ul.footer-border-bottom li:hover a {
    color: #fff;
}

ul.footer-border-bottom li a .fa {
    color: #f60;
    font-size: 6px;
}

.footer-border-bottom .footer-after-icon {
    font-size: 12px;
}

svg.footer-after-icon {
    font-size: 12px;
}

.subtitleslevel {
    position: relative;
    padding-bottom: 16px;
    padding-left: 16px;
}

.subtitleslevel:before {
    position: absolute;
    content: "";
    background: #f60;
    padding: 3px;
    left: 0;
    top: 8px;
}

h5.subtitleslevel.pb-2.headingItems {
    color: #006;
}

.advertise .card-body {
    background: #ffd3b5;
    border-radius: 4px;
}

.advertise button.btn.btnstyle2 {
    padding: 8px 26px;
    background: #006;
    color: #fff;
}

.advertise .card-body h3 {
    color: #006;
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
    padding-bottom: 12px;
}

.advertise .card-body form input,
textarea:focus {
    box-shadow: none !important;
}

.advertise .card-body form input,
textarea {
    border: 1px solid #ff6600 !important;
    font-size: 15px;
}
.advertise h1 {
    font-size: 34px;
}
.advertise .card-body .form-label {
    color: black;
    font-weight: 500;
    font-size: 15px;
}
.audience-content li {
    padding: 5px 0px;
}
.righticon-listing {
    color: #ff6600;
    padding-right: 5px;
}
.audience-content  a.emailcolorchange {
    color: #ff6600;
}
a.emailcolorchange {
    color: #ff6600;
}

.marketbox svg.investor_icons {
    color: #000066;
    padding-bottom: 15px;
    width: 60px;
    height: 60px;
}
.marketbox h4 {
    font-size: 22px;
    padding-bottom: 14px;
    font-weight: 500;
}
.marketbox {
    background: #ff66008c;
    padding: 24px;
    transition: 0.5s;
}
.marketbox p {
    color: #000;
    font-size: 16px;
}
.marketbox:hover {
    background: #ddd;
}
.intro-head {
    padding: 28px 0px 20px 0px;
}
.carrer-section .job-title {
    font-size: 26px;
    font-weight: bold;
    color: #ff6600;
    padding-top: 30px;
}
.carrer-section h1 {
    font-weight: bold;
    color: #ff6600;
    padding-bottom: 8px;
}
.application-job-form {
    background: #ddddddde;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: 0.5s;
}

form.job-apply-form {
    background: #006;
    padding: 20px;
    width: 50%;
    margin: 0 auto;
    position: absolute;
    border-radius: 4px;
    top: 2%;
    left: 0;
    right: 0;
}
form.job-apply-form .inputwith label {
    font-weight: 300;
    font-size: 15px;
}
h4.formheading-detail{
    color: #fff;
    padding: 12px 10px;
    font-size: 24px;
    font-weight: 500;
}
form.job-apply-form span.upload-cv {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    color: #000;
    border-radius: 4px;
    padding: 9px 10px;
    font-size: 12px;
    width: 100%;
    display: inline-block;
}
.uploadfile input {
    z-index: 1;
    position: absolute;
    opacity: 0;
}
form.job-apply-form .inputwith input, select {
    border-radius: 4px;
    padding: 8px !important;
    font-size: 12px !important;
    position: relative;
}
form.job-apply-form .inputwith input, select:focus{
    box-shadow: none;
}
form.job-apply-form .inputwith {
    width: 45%;
    margin: 6px 10px;
    display: inline-block;
    color: #fff;
}
form.job-apply-form .error {
    display: block;
    color: red;
    padding: 6px 10px;
    font-size: 12px;
}
form.job-apply-form .closeIcon {
    height: 26px;
    width: 26px;
    padding: 4px;
    position: absolute;
    top: 10px;
    text-align: center;
    right: 12px;
    color: #fff;
    background: #ff6600;
    font-weight: bold;
    border-radius: 50%;
    cursor: pointer;
}
.carrer-section .hiring-icon {
    width: 62px;
}
.news-slider-section iframe {
    width: 100%;
    /* padding: 0px 18px; */
}
.news-slider-section button.slick-arrow.slick-prev, button.slick-arrow.slick-next {
    border-radius: 50%;
    background: #f60;
    width: 30px;
    height: 30px;
}
.news-slider-section .slick-dots{
    display: none !important;
}
.subtitleslevel a {
    text-decoration: none;
    color: #000;
    transition: 0.4s;
}
.Releases .lead {
    font-weight: 500;
    font-size: 16px;
}
.Releases span.text-muted {
    color: #ff6600 !important;
}
.Releases p {
    padding: 12px 0px;
    font-size: 16px;
    color: black;
}
.Releases {
    border: 1px solid #ddd;
    padding: 24px;
}
.Releases h1 {
    font-size: 28px;
}
.subtitleslevel a:hover {
    color: #f60;
}
.live-news.padd.features.latest-news.blog h3 {
    font-size: 22px;
    padding: 30px 0px 10px 0px;
}

.weather-section {
    background: #343434;
}
.weather-wrapper h3 {
    margin-bottom: 10px;
    background: #e1f1fb;
    padding: 10px;
    font-size: 22px;
    border-radius: 4px;
    color: rgb(253, 253, 253);
}
.weather-section a.openweatherlink {
    font-size: 11px;
    text-transform: capitalize;
    position: absolute;
    right: 0;
    text-align: right;
    padding-right: 10px;
}
.weather-section .weather-cover {
    position: absolute;
    left: 0;
    right: 0;
    text-align: left;
    padding: 8px 26px;
    top: 18px;
}
.weather-section .forecastfont{
    font-weight: 200;
    font-size: 30px;
}
.weather-section .weather-cover h4, h2 {
    font-size: 22px;
    font-weight: 600;
    color: #fff;
}
.input-group.weather-input .weather-search {
    background: #f60;
    display: inline-block;
    justify-content: center;
    font-size: 40px;
    color: #fff;
}
.weather-input input {
    border-radius: 0px;
    background: #fff;
    border: 1px solid #f60;
    color: #000;
}
.weather-input input:focus{
    box-shadow: none;
}
.weather-section .weather-cover p {
    font-size: 14px;
    color: #fff;
    padding: 3px 0px;
    margin-bottom: 8px;
}


.error {
    font-size: 14px !important;
    color: #FF0000;
}
.social-icons-container{
    display: inline-flex;
    gap: 10px;
}
.social-icons {
    background-color: #fff;
    height: 35px;
    width: 35px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.social-icons svg {
    font-size: 25px;
}
.header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.social-icons-container .search-bar { 
    height: 35px;
    position: relative;
}
@media only screen and (max-width: 600px) {
    body {
        width: 100%;
    }

    /* ul.top-header-list.social-media {
        display: flex;
        padding-bottom: 3px;
    } */

    header .top-header input.search-btn-style {
        width: 162px;
    }

    .header-container{
        flex-direction: column;
    }
    .social-icons-container{
        gap: 5px;
    }
    .social-icons {
        height: 30px;
        width: 30px;
    }
    .social-icons svg {
        font-size: 20px;
    }
    .social-icons-container .search-bar { 
        height: 30px;
    }
}
span.starstyle {
    display: inline-block;
    font-size: 32px;
    color: red;
    position: absolute;
    top: -10px;
}
.slick-slide > div {
    padding: 0 10px !important;
}
.social-icons-container a:hover {
    background: #006 !important;
    box-shadow: 0 0 16px #ff6600f0;
    transition: .6s;
}
.social-icons-container a:hover svg{
    color: #fff !important;
}
.contact-section h5.fw-light.text-white {
    font-size: 17px;
}
.about-mylogo-info {max-width: 265px;float: left;}